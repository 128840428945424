import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import { Separator } from "@components/common/separator";
import Logo from "@components/logo";
import { useNavigation } from "@hooks/use-navigation";
import { getCopyright, getMenuURL } from "@utils";

const Footer = ({
  forceDarkTheme,
}: {
  forceDarkTheme?: boolean;
}): JSX.Element => {
  const t = useTranslations("common.footer");
  const { Link } = useNavigation();

  return (
    <div>
      <Separator />
      <footer className="mx-auto max-w-screen-md">
        <div className="grid grid-cols-2 gap-2 gap-y-4 pb-8 pt-16 md:grid-cols-4 md:gap-8 md:pb-16">
          <div className="col-span-full mb-8 md:col-span-2 md:mb-0">
            <div className="-mt-[18px] mb-[18px] flex justify-center md:justify-start">
              <Link href={getMenuURL("home")}>
                <Logo
                  type={"isolated"}
                  forceDarkTheme={forceDarkTheme}
                  width={120}
                  height={67}
                />
              </Link>
            </div>
            <div className="pb-6 text-center md:text-left">
              <p className="pb-1 text-sm text-light-foreground">
                {t("description")}
              </p>
              <span className="text-sm text-light-foreground">
                {t("copyright", { copyright: getCopyright() })}
              </span>
            </div>
            <div className="dark flex justify-center md:justify-start">
              <Button asChild variant={"secondary"}>
                <Link href={getMenuURL("contact")}> {t("contact")}</Link>
              </Button>
            </div>
          </div>
          <div className="text-left md:mx-auto">
            <h4 className="mb-7 text-lg font-medium">{t("brand")}</h4>
            <ul className="text-sm">
              <li className="mb-2">
                <Button
                  variant={"link"}
                  asChild
                  className="-ml-4 text-light-foreground"
                >
                  <Link href={getMenuURL("home")}>{t("menuHome")}</Link>
                </Button>
              </li>
              <li className="mb-2">
                <Button
                  variant={"link"}
                  asChild
                  className="-ml-4 text-light-foreground"
                >
                  <Link href={getMenuURL("app")}>{t("menuApp")}</Link>
                </Button>
              </li>
            </ul>
          </div>
          <div className="text-left md:mx-auto">
            <h4 className="mb-7 text-lg font-medium">{t("support")}</h4>
            <ul className="text-sm">
              <li className="mb-2">
                <Button
                  variant={"link"}
                  asChild
                  className="-ml-4 text-light-foreground"
                >
                  <Link href={getMenuURL("contact")}>{t("menuContact")}</Link>
                </Button>
              </li>
              <li className="mb-2">
                <Button
                  variant={"link"}
                  asChild
                  className="-ml-4 text-light-foreground"
                >
                  <Link href={getMenuURL("termsConditions")}>
                    {t("menuTermsConditions")}
                  </Link>
                </Button>
              </li>
              <li className="mb-2">
                <Button
                  variant={"link"}
                  asChild
                  className="-ml-4 text-light-foreground"
                >
                  <Link href={getMenuURL("privacyPolicy")}>
                    {t("menuPrivacyPolicy")}
                  </Link>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
