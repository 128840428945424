import { ReactNode } from "react";

import classNames from "classnames";

import { ScrollArea } from "@components/common/scroll-area";
import Footer from "@components/footer";
import Header from "@components/header/home";
import { Menu } from "@types";
import { getDevice, getParams } from "@utils";

const VerticalFullLayout = async ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): Promise<JSX.Element> => {
  const device = await getDevice();
  const { path } = await getParams();
  const selectedMenu: Menu = (path as Menu) || "home";

  return (
    <ScrollArea
      className={classNames(
        "h-full w-full max-w-full bg-background [&>div>div]:size-full",
        className,
      )}
    >
      <div className="h-full text-foreground">
        <div className="mx-auto h-full max-w-screen-xl px-6 pt-0 md:py-8">
          <div className="mx-auto flex h-full flex-col">
            <Header
              isMobile={device.type === "mobile"}
              selectedMenu={selectedMenu}
              forceDarkTheme={true}
            />
            <main className="flex flex-1 flex-col items-center justify-center pb-16 pt-8 text-center md:pt-16">
              {children}
            </main>
            <Footer forceDarkTheme={true} />
          </div>
        </div>
      </div>
    </ScrollArea>
  );
};

export default VerticalFullLayout;
