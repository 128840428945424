"use client";

import Status from "@components/status";
import VerticalFullLayout from "@layouts/vertical-full-layout";

const Error = ({
  error: { digest },
}: {
  error: Error & { digest?: string };
}): JSX.Element => (
  <VerticalFullLayout className="dark">
    <Status type="500" errorCode={digest} />
  </VerticalFullLayout>
);

export default Error;
